import './App.css';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

function App() {
  const dashboardRef = useRef([]);

  const embed = async (dashboardurl) => {
    const embeddingContext = await createEmbeddingContext();
    const { embedDashboard } = embeddingContext;
    const options = {
      url: dashboardurl,
      container: dashboardRef.current,
      scrolling: "no",
      height: "500px",
      width: "600px", footerPaddingEnabled: true, iconDisabled: true
    };

    embedDashboard(options)
  };

  useEffect(() => {
    fetch("https://d1vyxp04h8.execute-api.us-west-2.amazonaws.com/embed/anonymous-embed"
    ).then((response) => response.json()
    ).then((response) => { embed(response.EmbedUrl) })
  }, [dashboardRef]);

  return (
   <>
      <header>
        <h1>Emission Reporting based on Biomass Calculation in the Democratic Republic of Congo: 2014-2019</h1>
      </header>
      <main>
        <p>Introduction:</p>
        <p>This report presents a comprehensive overview of the emissions reduction program focused on conserving biomass to mitigate greenhouse gas emissions. The program aims to implement sustainable interventions that align with key dates and milestones while minimizing potential displacement risks. </p>
        <p>The emissions reduction program, centered around conserving biomass, has emerged as a model of sustainable climate action. Through continuous progress, effective strategies to minimize displacement risks, strong organizational arrangements, and sound financial planning, the program showcases the power of collaboration in addressing global challenges. By prioritizing environmental conservation and community engagement, we are fostering a resilient and sustainable future for generations to come.</p>
        <p>Below is a summary of the emissions from 2014 - 2017 in the Democratic Repulic of Congo</p>
        <div ref={dashboardRef} />
        <p>To see how these values were calculated you can view the <a href="https://vegmapper.notebook.us-west-2.sagemaker.aws/notebooks/VegMapper/MRV-Demo/Emission%20Reduction%20Biomass%20Overview.ipynb">Jupyter Notebook</a></p>
      </main>
    </>
  );
};

export default App;